import React, { useState } from 'react'
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from '@react-spring/web'

import data from './../assets/data/skills';
import './../assets/style/list.skills.css';

export default function App() {
  const [open, set] = useState(false)

  const springApi = useSpringRef()
  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { size: '20%', background: 'white', y: 0 },
    to: {
      size: open ? '100%' : '20%',
      background: open ? "transparent" : "white"
    },
  })

  const transApi = useSpringRef()
  const transition = useTransition(open ? data : [], {
    ref: transApi,
    trail: 400 / data.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  })

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springApi, transApi] : [transApi, springApi], [
    0,
    open ? 0.1 : 0.6,
  ])

  return (
    <div className="list_skills__wrapper">
      <animated.div
        style={{ ...rest, width: size, height: size }}
        className={`list_skills__container ${open ? "remove_box_shadow" : "add_box_shadow"}`}
        onClick={() => set(open => !open)}>
          { !open && 
            <div className="list_skills__description">
              <h2>Skills</h2>
              <h3>What I have learned <br/> click me!</h3>
            </div>
          }
        {transition((style, item) => (
          <animated.div
            className="list_skills__item"
            style={{ ...style, background: item.css, boxShadow: `0 0 42px ${item.hex}` }}
          >
            <h4> {item.title}</h4>
            <h5> {item.time} </h5>
          </animated.div>
        ))}
      </animated.div>
    </div>
  )
}
