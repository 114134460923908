export default [
    {
      name: 'Rare Wind',
      description: '#a8edea → #fed6e3',
      css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
      hex: '#fed6e3',
      height: 200,
      title: "ReactJs",
      time: 2019
    },
    {
      name: 'Deep Blue',
      description: '#e0c3fc → #8ec5fc',
      css: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
      hex: '#8ec5fc',
      height: 400,
      title: "NodeJs",
      time: 2019
    },
    {
      name: 'Ripe Malinka',
      description: '#f093fb → #f5576c',
      css: 'linear-gradient(135deg, #f093fb 0%, #f5576c 100%)',
      hex: '#f5576c',
      height: 400,
      title: "Java / Spring",
      time: 2019
    },
    {
      name: 'Sunny Morning',
      description: '#f6d365 → #fda085',
      css: 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)',
      hex: '#fda085',
      height: 200,
      title: "Base de datos Relacionales",
      time: 2019
    },
    {
      name: 'Perfect White',
      description: '#fdfbfb → #ebedee',
      css: 'linear-gradient(135deg, #E3FDF5 0%, #FFE6FA 100%)',
      hex: '#ebedee',
      height: 400,
      title: "C#",
      time: 2020
    },
    {
      name: 'Japan Blush',
      description: ' #ddd6f3 → #faaca8',
      css: 'linear-gradient(135deg, #ddd6f3 0%, #faaca8 100%, #faaca8 100%)',
      hex: '#faaca8',
      height: 200,
      title: "React-Native",
      time: 2020
    },
    {
      name: 'Lemon Gate',
      description: '#96fbc4 → #f9f586',
      css: 'linear-gradient(135deg, #96fbc4 0%, #f9f586 100%)',
      hex: '#f9f586',
      height: 400,
      title: "Base de datos No Relacionales",
      time: 2020
    },
    {
      name: 'Near Moon',
      description: '#5ee7df → #b490ca',
      css: 'linear-gradient(135deg, #5ee7df 0%, #b490ca 100%)',
      hex: '#b490ca',
      height: 400,
      title: "Blockchain",
      time: 2021
    },
    {
      name: 'Wild Apple',
      description: '#d299c2 → #fef9d7',
      css: 'linear-gradient(135deg, #d299c2 0%, #fef9d7 100%)',
      hex: '#fef9d7',
      height: 200,
      title: "Kotlin",
      time: 2021
    },
    {
      name: 'Ladoga Bottom',
      description: '#ebc0fd → #d9ded8',
      css: 'linear-gradient(135deg, #ebc0fd 0%, #d9ded8 100%)',
      hex: '#d9ded8',
      height: 400,
      title: "DevOps",
      time: 2021
    },
    {
      name: 'Saint Petersburg',
      description: '#f5f7fa → #c3cfe2',
      css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      hex: '#c3cfe2',
      height: 400,
      title: "NextJs",
      time: 2021
    },
    {
      name: 'Salt Mountain',
      description: ' #FFFEFF → #D7FFFE',
      css: 'linear-gradient(135deg, #FFFEFF 0%, #D7FFFE 100%)',
      hex: '#D7FFFE',
      height: 200,
      title: "Testing",
      time: 2021
    },
    {
      name: 'New York',
      description: ' #fff1eb → #ace0f9',
      css: 'linear-gradient(135deg, #fff1eb 0%, #ace0f9 100%)',
      hex: '#ace0f9',
      height: 400,
      title: "Microservicios",
      time: 2021
    },
    {
      name: 'Jap',
      description: ' #ddd6f3 → #faaca8',
      css: 'linear-gradient(135deg, #ddd6f3 0%, #faaca8 100%, #faaca8 100%)',
      hex: '#faaca8',
      height: 200,
      title: "Solidity",
      time: 2021
    },
    {
      name: 'Perfect White',
      description: '#fdfbfb → #ebedee',
      css: 'linear-gradient(135deg, #E3FDF5 0%, #FFE6FA 100%)',
      hex: '#ebedee',
      height: 400,
      title: "Python",
      time: 2021
    },
    {
      name: 'Soft Grass',
      description: ' #c1dfc4 → #deecdd',
      css: 'linear-gradient(135deg, #c1dfc4 0%, #deecdd 100%)',
      hex: '#deecdd',
      height: 400,
      title: "Swift",
      time: 2022
    },
  ]
  