import React from 'react';
import './../assets/style/cards.portafolio.css';
import imageEcommerceGamer from './../assets/images/ecommerce-gamer.png';
import imageCinemAll from './../assets/images/cineAll.png';
import imageMiniMarket from './../assets/images/mini-market.png';

const images = (type:number) => {
    switch(type){
        case 1: return imageEcommerceGamer;
        case 2: return imageCinemAll;
        case 3: return imageMiniMarket;
        default: return imageEcommerceGamer;
    }
}

const CardPortafolio = (props:any) => {

    return(
        <a className="CardPortafolio" key={props.key} href={props.link} target="_blank">
            <div className="CardPortafolio_img">
                <img src={images(props.image)} alt="Not Found image ecommerce" />
                <div className="CardPortafolio_linea"></div>
            </div>
            <div className="CardPortafolio_contenido">
                <label className="CardPortafolio_contenido__title">{props.title}</label>
                <p className="CardPortafolio_contenido__text"> 
                    {props.description}
                </p>
            </div>
        </a>
    )
}

export default CardPortafolio;