import React from "react";
import "./assets/style/global.css";
import "./assets/style/global.media.css";
import CardPortafolio from "./components/cards.portafolio";
import ListSkills from "./components/list.skills";
import { useSpring } from "react-spring";
import Proyectos from './assets/data/proyecto';
import iconGitHub from './assets/images/icon-github.png';
import iconInstagram from './assets/images/icon-instagram.png';
import iconOutlook from './assets/images/icon-outlook.jpg';
import iconCursor from './assets/images/scroll-bar.png';

function App() {
  const Styles = (data:any) => useSpring({
    loop: { reverse: true },
    from: data.from,
    to: data.to,
    delay: 5
  })

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">Pedro Grados</div>
        <div className="Links">
          <a href="#Home">Home</a>
          <a href="#Portafolio">Portafolio</a>
          <a href="#Skills">Skills</a>
        </div>
      </header>

      <div className="container">
        
          <section id="Home">
            <div>
              <h1 className="neonText__1">Full Stack Developer</h1>
              <h2 className="neonText__2">Pedro Grados</h2>
            </div>
            <img id="iconCursor" src={iconCursor} height={50} alt="Not found image cursor"/>
          </section>

          <section id="Portafolio">
            <div className="contenedor__informacion">
              <div className="icons-1" style={{left: 5, top: "50%"}}/>
              <div className="icons-1" style={{right: 0, top: 0}}/> 
              <div className="icons-1" style={{right: "50%", bottom: -25}}/> 
              <div className="icons-2" style={{right: -25, bottom: -25}}/> 
              <div className="icons-2" style={{right: "50%", top: 0}}/> 
              <h2>Portafolio</h2>
              <h3>What I have done</h3>
              <a className="more_proyects" href="https://github.com/pdrgrds">
                <p>
                  More
                </p>
              </a>
              <div className="list_proyects">
                {
                  Proyectos.map((item, index) => 
                    <CardPortafolio key={index} title={item.title} description={item.description} image={item.image} link={item.link} />
                  )
                }
              </div>
            </div>
          </section>

        
          <section id="Skills">
            <div className="contenedor__informacion">
              {/*<h2>Skills</h2>
              <h3>What I have learned</h3>*/}
              <div className="list_skills">
                <ListSkills />
              </div>
            </div>
          </section>

          <footer>
            <div>
              <h4>Social Media</h4>
              <a href="https://github.com/pdrgrds" target="_blank"><img src={iconGitHub} height={50} width={50}/></a>
              <a href="https://www.instagram.com/puhip.inc/" target="_blank"><img src={iconInstagram} height={50} width={50}/></a>
              <a href="mailto:pdrgrds@hotmail.com" target="_blank"><img src={iconOutlook} height={50} width={50}/></a>
              <p>@{new Date().getFullYear()} Pedro Grados</p>
            </div>
          </footer>
      </div >
    </div>
  );
}

export default App;
