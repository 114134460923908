export default [
    {
        title: "Proyecto - IShopGamer",
        description: "Es un proyecto ecommerce gamer, donde se administran y venden todo tipo de productos, hasta por categoría y control de stock. Además cuenta con gestión de usuarios y control de reportes a través de pdf personalizado.",
        image: 1,
        link: "https://github.com/pdrgrds/Tienda-Accesorios-Gamer"
    },
    {
        title: "Proyecto - CinemAll",
        description: "Es un proyecto de cine, donde puedes reservar y comprar tu ticket vía online de la pelicula y sala, además de comprar tus comestibles favoritos. Cuenta con el proceso de compras y de usuarios.",
        image: 2,
        link: "https://github.com/pdrgrds/Proyecto-Cine"
    },
    {
        title: "Proyecto - Admin-Market",
        description: "Es un proyecto administrador de un minimarket, donde puedes ir configurando tus productos y stock, cuenta con versión web se desarrolló con reactJs/typescript y la mobile que se desarrolló con kotlin.",
        image: 3,
        link: "https://github.com/pdrgrds/Mini-Market"
    }
];